/* eslint-disable object-property-newline */
import React, { useContext } from 'react';
import { t } from '@lingui/macro';
import OrganizationContext from 'components/utils/OrganizationContext';
import Sidebar from './Sidebar';
import SidebarLinks from './SidebarLinks';

function SidebarOrganization(props) {
  const organization = useContext(OrganizationContext);

  const links = [];
  links.push({ name: 'product', icon: 'stickies', to: `/${organization.slug}/manage/products`, text: t`Products` });
  if (!organization.is_guest) {
    links.push({ name: 'member', icon: 'person-circle', to: `/${organization.slug}/manage/member`, text: t`Members` });
    links.push({ name: 'worklogs', icon: 'clock', to: `/${organization.slug}/manage/worklogs`, text: t`Work log` });
    links.push({ name: 'customers', icon: 'people', to: `/${organization.slug}/manage/customers`, text: t`Customers` });
    links.push({ name: 'accounts', icon: 'wallet2', to: `/${organization.slug}/manage/accounts`, text: t`Accounts` });
  }
  if (organization.is_organization_manager) {
    if (organization.integrations.length) {
      links.push({ name: 'orga-integrations', icon: 'window-stack', to: `/${organization.slug}/integrations`, text: t`Integrations` });
    }
    links.push({ name: 'orga-edit', icon: 'gear', to: `/${organization.slug}/manage/settings`, text: t`Settings` });
  }

  return (
    <Sidebar context={<div className="organization-name">{ organization.name }</div>} {...props}>
      <ul className="nav flex-column">
        <SidebarLinks links={links} />
      </ul>
    </Sidebar>
  );
}

export default SidebarOrganization;

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import BacklogItemForm from 'components/forms/BacklogItemForm';
import ProductPage from 'components/page/ProductPage';
import OrganizationContext from 'components/utils/OrganizationContext';
import ProductContext from 'components/utils/ProductContext';
import DateFormat from 'components/ui/DateFormat';
import { LoaderContainer } from 'components/ui/Loader';
import Icon from 'components/ui/Icon';
import Kanban, { KanbanCol } from 'components/product/kanban/Kanban';
import ContentBlock from 'components/content/ContentBlock';
import SprintModal from 'components/modal/SprintModal';
import { statusNames } from 'components/utils/constants';
import { addMessage } from 'components/ui/Messages';
import SprintBurndown from 'components/product/SprintBurndown';
import ProductBurnup from 'components/product/ProductBurnup';
import SprintStats from 'components/product/SprintStats';
import Sprint from 'entity/Sprint';
import { useStateItem } from 'utils/Item';
import SimpleModal from 'components/modal/SimpleModal';
import NotFoundPage from './NotFoundPage';

const ResizableCol = function(props) {
  const [opened, setOpened] = useState(true);
  const { hideBtn } = props;

  return (
    <div className={'backlog-add-col d-none d-md-block' + (opened ? ' opened' : ' closed')}>
      { !hideBtn && (
        <button type="button" className="btn btn-transparent bt-resize" onClick={() => setOpened(val => !val)}>
          <Icon name={opened ? 'arrow-left-circle' : 'arrow-right-circle'} />
        </button>
      ) }
      { props.children }
    </div>
  );
};

const AddSimpleBacklogForm = function(props) {
  const { product, setRefresh, statuses } = props;
  const [version, setVersion] = useState(1);
  const [item, setItem] = useState({
    product: product.pk,
    status: product.statuses.find(s => s.status_type.value === 'backlog')
  });

  const handleSave = (data) => {
    setRefresh(old => old + 1);
    const newItem = {
      product: product.pk,
      status: product.statuses.find(s => s.status_type.value === 'backlog'),
    };
    if (data && data.item_type) {
      newItem.item_type = data.item_type;
    }
    if (data && data.status) {
      newItem.status = data.status;
    }
    setItem(newItem);
    setVersion(v => v + 1);
  };

  return (
    <BacklogItemForm
      key={version}
      layout="backlog"
      organization={product.organization}
      product={product}
      onSave={handleSave}
      backlogitem={item}
      showCreateMessage={false}
      statuses={statuses}>
    </BacklogItemForm>
  );
};

export { AddSimpleBacklogForm, ResizableCol };

function Statistics(props) {
  const { items, filterStatus } = props;
  if (!items) return null;
  const statuslist = items.filter(it => !filterStatus.includes(it.status.status_type.value));
  if (statuslist.length < 1) return null;
  return (
    <div className="content-block p-3 mb-1">
      <Trans>Items in other status</Trans>:{' '}
      { statuslist.map(item => (
        <span className="badge bg-secondary me-2" key={item.status.pk}>
          { item.status.resource_name }: { item.count_items }
        </span>
      )) }
    </div>
  );
}
Statistics.defaultProps = {
  filterStatus: ['todo', 'backlog']
};

function SprintBacklogPageContent(props) {
  const { sprint, product } = props;
  const [statistics, setStatistics] = useState();
  const [refresh, setRefresh] = useState(1);
  const [refreshSprintStats, setRefreshSprintStats] = useState(1);
  const [loading, setLoading] = useState(false);

  const getSprintStats = useCallback(() => {
    axios.get(`/backlog-items/statistics?product=${product.pk}&sprint=${sprint.pk}&group_by=status`).then((response) => {
      setStatistics(response.data.items);
    }).catch((err) => { addMessage('error-prod-stats', t`Unknown error`, t`Can't retrieve statistics`); });
  }, [product.pk, sprint.pk]);

  useEffect(() => {
    getSprintStats();
  }, [getSprintStats, refreshSprintStats]);

  let isEvalExceeded = false;
  if (product.estimate_mode.value === 'hours') {
    isEvalExceeded =
      (sprint.total_hours_effort ? sprint.total_hours_effort.minutes : 0) >
      (sprint.hours_effort ? sprint.hours_effort.minutes : 0);
  } else {
    isEvalExceeded = sprint.total_points_effort > sprint.points_effort;
  }

  const getTitle = col => {
    if (product.can_see_estimates && col.id !== 'backlog') {
      return (
        <h2 className="d-flex justify-content-between">
          { col.title }
          <span className="total">
            <strong><Trans>Estimate</Trans>: </strong>
            { product.estimate_mode.value === 'points' ? (
              <span className="estimate-value">
                { sprint.total_points_effort || '0' }
                { ` / ${sprint.points_effort || '0'}` }
              </span>
            ) : (
              <span className="estimate-value">
                { sprint.total_hours_effort ? sprint.total_hours_effort.human_format : '0h' }
                { ` / ${sprint.hours_effort ? sprint.hours_effort.human_format : '0h'}` }
              </span>
            ) }
          </span>
        </h2>
      );
    }
    return <h2>{ col.title }</h2>;
  };

  const onWillUpdate = () => {
    sprint.reload();
    getSprintStats();
    setRefreshSprintStats(v => v + 1);
  };

  const itemOptions = {};
  const cols = [];
  if (sprint.is_current) {
    itemOptions.params = 'subtasks=single&subtasks=parent&sprint=' + sprint.pk
    + '&sprint=null&' + product.getStatusParams(['backlog', 'todo']);
    cols.push(
      <KanbanCol key="1" id="backlog" sprint={null} status={product.statuses.find(s => s.status_type.value === 'backlog')}>
        { statusNames.backlog }
      </KanbanCol>
    );
    cols.push(
      <KanbanCol key="2" id="backlog-sprint" sprint={sprint.pk} status={product.statuses.find(s => s.status_type.value === 'todo')}>
        { statusNames.todo }
      </KanbanCol>
    );
  }
  else if (sprint.status.value === 'closed') {
    itemOptions.params = 'subtasks=single&subtasks=parent&sprint=' + sprint.pk + '&' + product.getStatusParams(['closed']);
    cols.push(
      <KanbanCol key="3" id="done" sprint={sprint.pk}>
        { statusNames.closed }
      </KanbanCol>
    );
  }
  else {
    itemOptions.params = 'subtasks=single&subtasks=parent&sprint=' + sprint.pk + '&sprint=null&' + product.getStatusParams(['backlog']);
    cols.push(
      <KanbanCol key="3" id="backlog" sprint={null}>
        { statusNames.backlog }
      </KanbanCol>
    );
    cols.push(
      <KanbanCol key="4" id="backlog-sprint" sprint={sprint.pk}>
        <Trans>Sprint backlog</Trans>
      </KanbanCol>
    );
  }

  return (
    <div className="d-md-flex">
      <ResizableCol>
        <ContentBlock
          className="p-3">
          <div className="dates">
            <DateFormat
              datestring={sprint.date_from}
              day="numeric"
              month="long"
              year="numeric"/>
            {' '}
            <Icon name="arrow-right"/>
            {' '}
            <DateFormat
              datestring={sprint.date_to}
              day="numeric"
              month="long"
              year="numeric"/>
          </div>
          {sprint.goal && (
            <div className="mt-3 sprint-goal">
              <div className="d-flex">
                <h3 className="h6 mb-0 col-4"><Trans>Sprint goal</Trans></h3>
                <div className="ps-2 flex-grow-1">
                  <pre>{ sprint.goal }</pre>
                </div>
              </div>
            </div>
          )}
          {product.can_add_item && sprint.status.value !== 'closed' && (
            <AddSimpleBacklogForm
              product={product}
              setLoading={setLoading}
              setRefresh={setRefresh} />
          )}
          {product.can_see_worklogs && (sprint.status.value === 'closed' || sprint.is_current) && (
            <div className="mt-3">
              {product.sprint_graph && product.sprint_graph.value === 'burndown' ? (
                <SprintBurndown product={product} sprint={sprint} nosettings/>
              ) : (
                <ProductBurnup product={product} sprint={sprint} noTodos nosettings/>
              )}
            </div>
          )}
        </ContentBlock>
      </ResizableCol>
      <div className="flex-grow-1 relative">
        { loading && (
          <LoaderContainer/>
        ) }
        { product.can_see_estimates && isEvalExceeded && (
          <div className="alert alert-warning">
            <Trans>The total effort of the selected items exceeds the sprint capacity.</Trans>
          </div>
        ) }
        <Statistics
          items={statistics}
          filterStatus={sprint.status.value === 'closed' ? ['closed'] : ['todo', 'backlog']}/>
        { product.can_see_estimates && (
          <SprintStats refresh={refreshSprintStats} product={product} sprint={sprint}/>
        )}
        <Kanban
          product={product}
          refresh={refresh}
          layout="backlog"
          itemsOptions={itemOptions}
          readonly={!product.can_edit_item}
          getTitle={getTitle}
          sprint={sprint}
          onMove={() => {}}
          onWillUpdate={onWillUpdate}>
          { cols }
        </Kanban>
      </div>
    </div>
  );
}

export default function SprintBacklogPage() {
  const { sprintPk } = useParams();
  const organization = useContext(OrganizationContext);
  const product = useContext(ProductContext);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const sprint = useStateItem('sprints:' + sprintPk, true);

  useEffect(() => {
    const s = new Sprint();
    s.load(sprintPk).catch(err => {
      if (err.response && err.response.status === 404) {
        setError(404);
      }
      else {
        addMessage('load-sprint', t`Unknown error`, t`Can't load sprint`);
      }
    });
  }, [product.pk, sprintPk]);

  if (error === 404) {
    return <NotFoundPage breadcrumb={breadcrumb}/>;
  }

  const breadcrumb = [
    <Link key="1" to="/"><Trans>Home</Trans></Link>,
    <Link key="2" to={`/${organization.slug}`}>{ organization.name }</Link>,
    <Link key="3" to={`/${organization.slug}/${product.slug}`}>{ product.name }</Link>,
    <Link key="4" to={`/${organization.slug}/${product.slug}/sprints`}><Trans>Sprints</Trans></Link>,
    t`Sprint planning`
  ];

  const actions = product.can_edit_sprint ? [
    <SprintModal
      key="1"
      product={product}
      organization={organization}
      pk={sprintPk}
      title={t`Edit sprint`}
      modalTitle={t`Edit sprint`} />,
  ] : [];
  if (sprint) {
    const sprintLink = `/${organization.slug}/${product.slug}/sprints/${sprint.pk}`;
    if (product.can_edit_sprint) {
      actions.push(
        <Link
          key="2"
          className="btn btn-outline-secondary"
          to={sprintLink + '/review'}>
          { sprint.review ? t`Review` : t`Add review` }
        </Link>
      );
      actions.push(
        <Link
          key="3"
          className="btn btn-outline-secondary"
          to={sprintLink + '/retrospective'}>
          { sprint.retrospective ? t`Retrospective` : t`Add retrospective` }
        </Link>
      );
    }
    else {
      if (sprint.review) {
        actions.push(
          <SimpleModal
            key="2b"
            title={t`Sprint review`}
            variant="outline-secondary"
            buttonTitle={t`Review`}
            className="modal-sprint-review modal-lg">
            <div
              className="rich-content"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: sprint.review }}/>
          </SimpleModal>
        );
      }
      if (sprint.retrospective) {
        actions.push(
          <SimpleModal
            key="3b"
            title={t`Sprint retrospective`}
            variant="outline-secondary"
            buttonTitle={t`Retrospective`}
            className="modal-sprint-retrospective modal-lg">
            <div
              className="rich-content"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: sprint.retrospective }}/>
          </SimpleModal>
        );
      }
    }

  }
  if (sprint && sprint.status.value === 'open' && !sprint.is_current) {
    actions.push(
      <button key="4" onClick={e => activate(sprint.pk)} type="button" className="btn btn-outline-secondary">
        <Trans>Start sprint</Trans>
      </button>
    );
  }

  const activate = pk => {
    setLoading(true);
    axios.put('/sprints/' + pk + '/activate').then(() => {
      axios.get('/sprints/' + pk).then(res => {
        sprint.loadFromObject(res.data, true);
        setLoading(false);
      }).catch(err => {
        setLoading(false);
        addMessage('sprint-load-' + pk, t`Unknown error`, t`Impossible to reload sprint`);
      });
    }).catch(err => {
      setLoading(false);
      if (err.response.status === 400) {
        addMessage(err.response.config.url, t`Impossible to start sprint`, err.response.data.message);
      }
      else {
        addMessage(err.response.config.url, t`Unknown error`, t`Impossible to start sprint`);
      }
    });
  };

  return (
    <ProductPage
      name="sprint-page"
      title={sprint ? (t`Sprint planning` + ' -  ' + sprint.name) : t`Sprint planning`}
      breadcrumb={breadcrumb}
      actions={actions}
      nocontent>
      { sprint ? (
        <>
          { loading && (
            <LoaderContainer fullpage />
          ) }
          <SprintBacklogPageContent sprint={sprint} product={product} />
        </>
      ) : (
        <ContentBlock className="p-3 kanban-top-content">
          <LoaderContainer height="3" static/>
        </ContentBlock>
      ) }
    </ProductPage>
  );
}

import React, { useContext, useEffect, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Icon from 'components/ui/Icon';
import ProductPage from 'components/page/ProductPage';
import OrganizationContext from 'components/utils/OrganizationContext';
import ProductContext from 'components/utils/ProductContext';
import BacklogItemModal from 'components/modal/BacklogItemModal';
import Kanban, { KanbanCol } from 'components/product/kanban/Kanban';
import ContentBlock from 'components/content/ContentBlock';
import { addMessage } from 'components/ui/Messages';
import { LoaderContainer } from 'components/ui/Loader';
import { statusNames } from 'components/utils/constants';
import { ConfirmButton } from 'components/utils';
import KanbanSearch from 'components/product/kanban/KanbanSearch';
import { useParamsFilters } from 'components/filters/Filters';

const SprintKanban = function(props) {
  const organization = useContext(OrganizationContext);
  const { sprint, ...rest } = props;
  const { product } = rest;

  const definition_of_done = product.definition_of_done || organization.definition_of_done;

  if (sprint === null) {
    const baseLink = '/' + product.organization.resource_slug + '/' + product.slug + '/';
    return (
      <ContentBlock className="p-3">
        <Card>
          <Card.Body className="p-4">
            <h2><Trans>No sprint is active</Trans></h2>
            <p><Trans>
              Starts by adding items to the <Link to={baseLink + 'product-backlog'}>backlog</Link>{' '}
              and create a <Link to={baseLink + 'sprints'}>sprint</Link>.
            </Trans></p>
          </Card.Body>
        </Card>
      </ContentBlock>
    );
  }
  if (sprint) {
    return (
      <Kanban
        itemsOptions={{ params: `sprint=${sprint.pk}&subtasks=single&subtasks=children` }}
        {...rest}>
        <KanbanCol id="todo" status={product.statuses.find(s => s.status_type.value === 'todo')}>
          { statusNames.todo }
        </KanbanCol>
        { product.statuses.filter(s => s.status_type.value === 'in_progress').map(status => (
          <KanbanCol key={status.pk} id={status.resource_name} status={status}>
            { status.resource_name }
          </KanbanCol>
        )) }
        <KanbanCol id="done" status={product.statuses.find(s => s.status_type.value === 'done')}>
          { statusNames.done }{' '}
          { definition_of_done && (
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="tooltip-definition-of-done"><pre>{ definition_of_done }</pre></Tooltip>}>
              {({ ref, ...triggerHandler }) => (
                <span {...triggerHandler} ref={ref}>
                  <Icon name="info-circle"/>
                </span>
              )}
            </OverlayTrigger>
          ) }
        </KanbanCol>
      </Kanban>
    );
  }
  return (
    <ContentBlock className="relative">
      <LoaderContainer height="3"/>
    </ContentBlock>
  );
};

export default function KanbanPage() {
  const organization = useContext(OrganizationContext);
  const product = useContext(ProductContext);
  const [refresh, setRefresh] = useState(1);
  const [sprint, setSprint] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { filters } = useParamsFilters();
  useEffect(() => {
    if (product.mode.value === 'scrum') {
      axios.get('/sprints?meta=&current=true&product=' + product.pk).then(res => {
        if (res.data.items.length < 1) {
          setSprint(null);
        }
        else {
          setSprint(res.data.items[0]);
        }
      }).catch(err => {
        addMessage('current-sprint', t`Unknown error`, t`Can't load current sprint`);
      });
    }
    // product.mode.value can't change for same product.pk
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.pk]);

  const onClose = (modified) => {
    if (modified) setRefresh(refresh + 1);
  };

  const closeSprint = pk => {
    setLoading(true);
    axios.put('/sprints/' + pk + '/close').then(() => {
      setLoading(false);
      addMessage('spint-closed-' + pk, t`Sprint ${sprint.name}`, t`Sprint has been closed`);
      history.push('/' + organization.slug + '/' + product.slug + '/sprints');
    }).catch(err => {
      setLoading(false);
      if (err.response.status === 400) {
        addMessage(err.response.config.url, t`Impossible to start sprint`, err.response.data.message);
      }
      else {
        addMessage(err.response.config.url, t`Unknown error`, t`Impossible to start sprint`);
      }
    });
  };
  const closeKanban = () => {
    setLoading(true);
    axios.put(`/products/${product.pk}/close-kanban`).then(res => {
      setLoading(false);
      setRefresh(refresh + 1);
      addMessage('kanban-item-closed', t`Done items closed`, res.data.message);
    }).catch(err => {
      setLoading(false);
      addMessage(err.response.config.url, t`Unknown error`, t`Impossible to update items`);
    });
  };

  const pageTitle = t`Kanban` + (sprint ? ' - ' + sprint.name : (
    (product.mode.value === 'scrum' && sprint !== null) ? (' | ' + t`Loading…`) : ''
  ));
  const breadcrumb = [
    <Link key="1" to="/"><Trans>Home</Trans></Link>,
    <Link key="2" to={`/${organization.slug}`}>{ organization.name }</Link>,
    <Link key="3" to={`/${organization.slug}/${product.slug}`}>{ product.name }</Link>,
    pageTitle
  ];
  const actions = [];
  if (product.can_add_item && (sprint || product.mode.value === 'kanban')) {
    actions.push(<BacklogItemModal
      key="1"
      status="todo"
      product={product}
      organization={organization}
      sprint={sprint}
      onClose={onClose}/>);
  }

  if (product.can_edit_sprint) {
    if (sprint) {
      actions.push(
        <ConfirmButton
          key="2"
          message={
            <Trans>Are you sure you want to close this sprint ?</Trans>
          }
          variant="btn btn-outline-secondary"
          onConfirm={e => closeSprint(sprint.pk)}>
          <Trans>Close Sprint</Trans>
        </ConfirmButton>
      );
    } else if (product.mode.value === 'kanban') {
      actions.push(
        <ConfirmButton
          key="3"
          message={
            <Trans>Are you sure you want to close all the items with status <em>done</em>?</Trans>
          }
          variant="btn btn-outline-secondary"
          onConfirm={e => closeKanban()}>
          <Trans>Close Items</Trans>
        </ConfirmButton>
      );
    }
  }

  const definition_of_done = product.definition_of_done || organization.definition_of_done;

  return (
    <ProductPage
      name="kanban-page"
      title={pageTitle}
      breadcrumb={breadcrumb}
      actions={actions}
      nocontent>
      <div className="relative">
        { loading && (
          <LoaderContainer />
        ) }
        <ContentBlock
          className="p-3 kanban-top-content">
          <KanbanSearch product={product}/>
        </ContentBlock>
        {product.mode.value === 'scrum' ? (
          <SprintKanban
            product={product}
            sprint={sprint}
            refresh={refresh}
            filters={filters}
            readonly={!product.can_edit_item}/>
        ) : (
          <Kanban
            product={product}
            refresh={refresh}
            filters={filters}
            itemsOptions={{ params: product.getStatusParams(['todo', 'in_progress', 'done']) + '&subtasks=single&subtasks=children' }}>
            <KanbanCol id="todo" status={product.statuses.find(s => s.status_type.value === 'todo')}>
              { statusNames.todo }
            </KanbanCol>
            { product.statuses.filter(s => s.status_type.value === 'in_progress').map(status => (
              <KanbanCol key={status.pk} id={status.resource_name} status={status}>
                { status.resource_name }
              </KanbanCol>
            )) }
            <KanbanCol id="done" status={product.statuses.find(s => s.status_type.value === 'done')}>
              { statusNames.done }{' '}
              { definition_of_done && (
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="tooltip-definition-of-done"><pre>{ definition_of_done }</pre></Tooltip>}>
                  {({ ref, ...triggerHandler }) => (
                    <span {...triggerHandler} ref={ref}>
                      <Icon name="info-circle"/>
                    </span>
                  )}
                </OverlayTrigger>
              ) }
            </KanbanCol>
          </Kanban>
        )}
      </div>
    </ProductPage>
  );
}

import { modelsApi } from 'components/utils/constants';
import Entity from './Entity';

export default class User extends Entity {
  constructor() {
    super(['pk'], modelsApi.User.api + '/:pk', modelsApi.User.api, [
      'pk',
      'full_name',
      'email',
      'is_active',
      'private_phone',
      'professional_phone',
      'mobile_phone',
      'memberships',
    ]);
  }

  get defaults() {
    return {
      pk: null,
      full_name: null,
      email: null,
      private_phone: null,
      professional_phone: null,
      mobile_phone: null,
      memberships: [],
    };
  }

  toString() {
    return this.full_name;
  }

  is_organization_manager(only_active = true) {
    return this.memberships.some(m => (
      m.roles.some(r => r[0] === 'role_organization_manager') &&
      (!only_active || m.organization.is_active)
    ));
  }

  get can_have_worklogs() {
    // Don't filter active organizations, a user can have worklogs
    // in an inactive organizations and old worklogs can be read.
    return this.memberships.some(m => (
      m.roles.some(r => r[0] !== 'role_guest')
    ));
  }

  get can_add_product() {
    return this.is_organization_manager();
  }
}

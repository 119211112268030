import React, { useContext, useEffect, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Link } from 'react-router-dom';
import Datatable from 'components/datatable/Datatable';
import AppContext from 'AppContext';
import UserPage from 'components/page/UserPage';
import BacklogModalContext, { BacklogModalContextProvider } from 'components/modal/BacklogModalContext';
import { useStateItem } from 'utils/Item';
import { ColorSticker } from 'components/ui/ColorSticker';
import Icon from 'components/ui/Icon';
import DateFormat from 'components/ui/DateFormat';
import BacklogItemViewModal from 'components/modal/BacklogItemViewModal';
import Organization from 'entity/Organization';
import OrganizationContext from 'components/utils/OrganizationContext';
import Product from 'entity/Product';
import ProductContext from 'components/utils/ProductContext';
import BacklogItem from 'entity/BacklogItem';

function UserBacklogItemsPageContexts(props) {
  const { item } = useContext(BacklogModalContext);
  const [organization, setOrganization] = useState(null);
  const [product, setProduct] = useState(null);
  useEffect(() => {
    if (item) {
      const org = new Organization();
      org.slug = item.product.organization.resource_slug;
      org.loadFromState(item.product.organization.pk, item.product.organization.resource_slug).then(() => {
        setOrganization(org);
      });
      const prod = new Product();
      prod.slug = item.product.resource_slug;
      prod.loadFromState(item.product.pk, {
        organizationSlug: item.product.organization.resource_slug,
        productSlug: item.product.resource_slug
      }).then(() => {
        setProduct(prod);
      });
    }
  }, [item]);
  if (!organization || organization.pk !== item.product.organization.pk ||
    !product || product.pk !== item.product.pk) {
    return (
      <UserBacklogItemsPageContent {...props} />
    );
  }
  return (
    <>
      <OrganizationContext.Provider value={organization} >
        <ProductContext.Provider value={product} >
          <BacklogItemViewModal item={item} />
        </ProductContext.Provider>
      </OrganizationContext.Provider>
      <UserBacklogItemsPageContent {...props} />
    </>
  );
}
function UserBacklogItemsPageContent(props) {
  const { showModal } = useContext(BacklogModalContext);
  const refresh = useStateItem('backlog-items');

  const filtersBase = ['assigned_user', 'organization', 'product'];

  const columnsList = [
    { name: 'reference', label: t`Reference`, active: true, locked: true },
    { name: 'item_type', label: t`Type`, active: true, group: t`Category` },
    { name: 'title', label: t`Name`, active: true, locked: true },
    { name: 'status', label: t`Status`, active: true },
    { name: 'sprint', label: t`Sprint` },
    { name: 'created_at', label: t`Creation date` },
    { name: 'reporter', label: t`Reporter`, group: t`People` },
    { name: 'assignee', label: t`Assignee`, active: true, group: t`People` },
    { name: 'priority', label: t`Priority`, group: t`Category` },
    { name: 'product', label: t`Product`, active: true },
    { name: 'subtasks_count', label: t`Subtasks`, group: t`Subtasks` },
    { name: 'parent', label: t`Parent`, group: t`Subtasks` },
    { name: 'parent_name', label: t`Parent name`, group: t`Subtasks` },
    { name: 'epic', label: t`Epic`, group: t`Category` },
    { name: 'tags', label: t`Tags`, group: t`Category` },
    { name: 'affects_versions', label: t`Affects versions`, group: t`Category` },
    { name: 'fix_versions', label: t`Fix versions`, group: t`Category` },
    { name: 'business_value', label: t`Business value`, group: t`Work` },
    { name: 'need_estimate', label: t`Need estimate`, group: t`Work` },
    { name: 'hours_estimate', label: t`Hours estimate`, group: t`Work` },
    { name: 'points_estimate', label: t`Points estimate`, group: t`Work` },
    { name: 'total_work', label: t`Worklogs`, group: t`Work` },
    { name: 'deadline', label: t`Deadline`, group: t`Work` }
  ];
  return (
    <Datatable
      api="backlog-items"
      editHandler={false}
      canDelete={false}
      refresh={refresh}
      filtersBase={filtersBase}
      columnsKey="blitems-myitems"
      columnsList={columnsList}
      filtersExtra={['status_type', 'parent', 'subtasks', 'need_estimate']}
      Entity={BacklogItem}>
      <Datatable.Column name="reference">
        {(item) => (
          <a
            href={item.path}
            className="me-2"
            onClick={(e) => {
              e.preventDefault();
              showModal(item);
            }} >
            { item.resource_reference }
          </a>
        )}
      </Datatable.Column>
      <Datatable.Column name="item_type">
        {item => (item.item_type ? (
          <>
            <ColorSticker color={item.item_type.color} />
            <span className="label">{ item.item_type.resource_name }</span>
          </>
        ) : '')}
      </Datatable.Column>
      <Datatable.Column name="title"/>
      <Datatable.Column name="sprint"/>
      <Datatable.Column name="product"/>
      <Datatable.Column name="status">
        {(item) => (
          <>
            { item.status.resource_name }
            { ['done', 'closed'].includes(item.status.status_type.value) && (
              item.resolution.value !== 'done' ? ` (${item.resolution.label})` : ''
            )}
          </>
        )}
      </Datatable.Column>
      <Datatable.Column name="created_at">
        {(item) => (
          <DateFormat
            datestring={item.created_at}
            hour12={false}
            year="numeric"
            month="short"
            day="numeric"
            hour="numeric"
            minute="numeric" />
        )}
      </Datatable.Column>
      <Datatable.Column name="reporter" />
      <Datatable.Column name="assignee" />
      <Datatable.Column name="epic">
        {item => (item.epic ? (
          <>
            <ColorSticker color={item.epic.color} />
            <span className="label">{ item.epic.resource_name }</span>
          </>
        ) : '')}
      </Datatable.Column>
      <Datatable.Column name="affects_versions">
        {(item) => item.affects_versions.map(v => v.resource_name).join(', ')}
      </Datatable.Column>
      <Datatable.Column name="fix_versions">
        {(item) => item.fix_versions.map(v => v.resource_name).join(', ')}
      </Datatable.Column>
      <Datatable.Column name="tags">
        {(item) => item.tags.join(', ')}
      </Datatable.Column>
      <Datatable.Column name="priority">
        {(item) => (item.priority.value !== 'undefined' ? item.priority.label : '')}
      </Datatable.Column>
      <Datatable.Column name="subtasks_count">
        {(item) => (item.subtasks_count > 0 ? item.subtasks_count : '')}
      </Datatable.Column>
      <Datatable.Column name="parent">
        {(item) => (item.parent ? (
          <a
            href={item.path}
            className="me-2"
            onClick={(e) => {
              e.preventDefault();
              const refItem = {
                ...item.parent,
                product: item.product
              };
              showModal(refItem);
            }} >
            { item.parent.resource_reference }
          </a>
        ) : '')}
      </Datatable.Column>
      <Datatable.Column name="parent_name">
        {(item) => (item.parent ? item.parent.resource_name : '')}
      </Datatable.Column>
      <Datatable.Column name="business_value" />
      <Datatable.Column name="hours_estimate">
        {(item) => (item.hours_estimate ? item.hours_estimate.human_format : '')}
      </Datatable.Column>
      <Datatable.Column name="points_estimate">
        {(item) => (item.points_estimate === null ? '' : item.points_estimate)}
      </Datatable.Column>
      <Datatable.Column name="total_work">
        {(item) => (item.total_work ? item.total_work.human_format : '')}
      </Datatable.Column>
      <Datatable.Column name="need_estimate">
        {(item) => (item.need_estimate ? (
          <Icon name="check" title={t`Need estimate`} />
        ) : '')}
      </Datatable.Column>
      <Datatable.Column name="deadline">
        {item => (item.deadline ? (
          <DateFormat datestring={item.deadline} />
        ) : '')}
      </Datatable.Column>
    </Datatable>
  );
}

export default function UserBacklogItemsPage() {
  const { user } = useContext(AppContext);
  const breadcrumb = [
    <Link key="1" to="/"><Trans>Home</Trans></Link>,
    t`Items list`
  ];

  return (
    <UserPage
      name="backlog-items-list-page"
      title={t`Items list`}
      breadcrumb={breadcrumb}>
      <BacklogModalContextProvider>
        <UserBacklogItemsPageContexts user={user}/>
      </BacklogModalContextProvider>
    </UserPage>
  );
}

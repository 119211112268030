import React, { useContext, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Form, FormContext, FormContextProvider } from 'components/form';

function slugify(str, separator = '-') {
  return str
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '')
    .replace(/\s+/g, separator);
}

function AccountFinalForm(props) {
  const { onSave, onCancel, organization, isEdit } = props;
  const [slugEdit, setSlugEdit] = useState(isEdit);
  const { item } = useContext(FormContext);

  const onInput = (e) => {
    if (!slugEdit) item.slug = slugify(e.target.value).slice(0, 15);
  };

  return (
    <Form
      name="account-add-form"
      noValidate
      onSave={onSave}>
      <Form.Field id="name" name="name" onInput={onInput} required />
      { slugEdit ? (
        <Form.Field id="slug" name="slug" required />
      ) : (
        <>
          <em><Trans>Slug</Trans>: </em>
          <Form.Field type="hidden" id="slug" name="slug" required />
          { item.slug }
          <button className="btn btn-outline-secondary" type="button" onClick={() => { setSlugEdit(true); }}>
            <Trans>Edit</Trans>
          </button>
        </>
      ) }
      <Form.Many2One id="customer" name="customer" api={'/customers?organization=' + organization.pk} required />
      <Form.Many2One id="manager" name="manager" api={`/members?organization=${organization.pk}&role=role_member`} />
      <Form.Field id="time_budget" name="time_budget" placeholder="100h" />
      { item.time_budget && (
        <Form.Field
          id="notification_threshold"
          name="notification_threshold"
          label={t`Notification threshold (% of time budget)`}
        />
      )}
      <div className="actions">
        <button className="btn btn-primary" type="submit">
          <Trans>Save</Trans>
        </button>
        <button className="btn btn-outline-secondary" type="button" onClick={onCancel}><Trans>Cancel</Trans></button>
      </div>
    </Form>
  );
}

const AccountForm = function(props) {
  const { onSave, onCancel, organization, account } = props;

  return (
    <FormContextProvider item={account} api="/accounts">
      <AccountFinalForm
        isEdit={account && !!account.pk}
        organization={organization}
        onSave={onSave}
        onCancel={onCancel} />
    </FormContextProvider>
  );
};

export default AccountForm;
